import React from 'react';
import Layout from 'src/components/layout/main';
import SEO from 'src/components/seo';
import Container from 'src/components/base/container';
import Grid from 'src/components/base/grid';
import Section from 'src/components/base/section';
import { FormGroup } from 'src/components/base/form';
import Button from 'src/components/base/button';
import Typography from 'src/components/base/typography';
import SuccessAlert from 'src/components/alert/success';

const ContactPage = (props) => {

    const nameRef = React.useRef();
    const emailRef = React.useRef();
    const messageRef = React.useRef();
    const [submitted, setSubmitted] = React.useState(false);

    const encodeForm = (formName, formData) => {
        // encode one key value pair
        const encode = ([k,v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v);
        // return encoded string from params
        return [
            encode(['form-name', formName]),
            ...Object.entries(formData).map(encode),
        ].join('&');
    }

    const sendForm = (form) => {
        return fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encodeForm(form.getAttribute("name"), {
                name: nameRef.current.value,
                email: emailRef.current.value,
                message: messageRef.current.value,
            })
        })
    }

    const handleSubmit = (e) => {

        e.preventDefault();

        const form = e.target;

        return sendForm(form)
            .then(() => {
                // clear form
                form.querySelectorAll('input:not([type="submit"]').forEach(elem => {
                  elem.value = '';
                });
                // update state to display success message
                setSubmitted(true);
            })
            .catch(error => alert(error));
    }

    const ContactGreeting = (text) => {

        const greeting = 'Tell Us About Your Project';
        const subtext = 'Contact us for a free consultation';

        return (
          <>
            <Typography family="display" weight="bold" italic contents>
              <Typography
                size="1"
                style={{ width: 280, margin: "0 auto 0 0" }}
                data-hidden="mobile tablet"
              >
                {greeting}
              </Typography>
              <Typography size="2" data-hidden="desktop" align="center" block>
                {greeting}
              </Typography>
            </Typography>
            <Section top="2rem" data-hidden="mobile tablet">
              <Typography block>{subtext}</Typography>
            </Section>
            <Section top="1rem" data-hidden="desktop">
              <Typography block align="center">{subtext}</Typography>
            </Section>
          </>
        )
    }

    const ContactForm = () => {
        return (
            <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                className="contact-form"
                id="contact"
            >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                    <Typography as="label">
                        Don’t fill this out:{" "}
                        <input name="bot-field" />
                    </Typography>
                </p>
                <Section bottom="1rem">
                    <FormGroup
                        inputRef={nameRef}
                        label="Your name"
                        name="name"
                        placeholder="e.g. John Smith"
                        type="text"
                        required
                    />
                </Section>
                <Section bottom="1rem">
                    <FormGroup
                        inputRef={emailRef}
                        label="Email"
                        name="email"
                        placeholder="name@example.com"
                        type="email"
                        required
                    />
                </Section>
                <Section bottom="1rem">
                    <FormGroup
                        inputRef={messageRef}
                        label="Message"
                        name="message"
                        placeholder="Write your message"
                        type="text"
                        inputAs="textarea"
                        required
                    />
                </Section>
                <Section y="1rem" style={{textAlign: 'right'}}>
                    <Button
                        as="input"
                        type="submit"
                        value="Submit"
                        responsive
                    />
                </Section>
            </form>
        );
    }

    return (
      <Layout page="/contact">
        <SEO title="Contact" />
        <Section color="dark" y="2rem" style={{ flexGrow: 1 }}>
          <Container small="480px">
            <Section top="2rem">
              <Grid.row>
                <Grid.column size="6">
                  <Section padding="1rem">
                    <Container large="90%">
                      <ContactGreeting />

                      <Section top="2rem" bottom="0">
                        {submitted && (
                          <SuccessAlert>
                            Thank you for your message. We will be in touch
                            shortly.
                          </SuccessAlert>
                        )}
                      </Section>
                    </Container>
                  </Section>
                </Grid.column>

                <Grid.column size="6">
                  <Section padding="1rem">
                    <ContactForm />
                  </Section>
                </Grid.column>
              </Grid.row>
            </Section>
          </Container>
        </Section>
      </Layout>
    )
}

export default ContactPage;